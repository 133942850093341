import {
    Box,
    ListItemIcon,
    MenuItem,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { main } from '@static/menus';
import { rem } from '@utils';
import { useNavigate } from 'react-router-dom';

interface MainMenuProps {
    origin: 'INTERNAL' | 'EXTERNAL';
}

const Main: React.FC<MainMenuProps> = ({ origin }) => {
    const { typography, palette } = useTheme();
    const navigate = useNavigate();

    return (
        <Box>
            <Stack sx={{ padding: 0 }}>
                {main.slice(0, origin === 'INTERNAL' ? 5 : 4)
                    .map(({ id, title, href, icon }) => (
                        <MenuItem
                            key={id}
                            sx={{ p: '13px 8px' }}
                            onClick={() => navigate(href)}>
                            <ListItemIcon>
                                <I
                                    color={palette.darkGray}
                                    icon={icon}
                                    fontSize={rem(16)} />
                            </ListItemIcon>
                            <Typography
                                color={palette.darkGray}
                                variant="inherit"
                                fontSize={rem(13)}
                                fontWeight={!window.location.href.includes(href) ?
                                    typography.fontWeightSemiBold :
                                    typography.fontWeightBold}>
                                {title}
                            </Typography>
                        </MenuItem>
                    ))}
            </Stack>
        </Box>
    );
};

export { Main };
