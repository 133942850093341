import { ImageProps } from '@interfaces';
import { Main } from './styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Image: React.FC<ImageProps> = (
    { src, alt, maxHeight, objectFit, cursor }: ImageProps) => {
    return (
        <Main cursor={cursor} maxHeight={maxHeight} objectFit={objectFit}>
            <div className="WrapAnchorPicture">
                <div className="AnchorPicture">
                    <div className="WrapPicture">
                        <picture>
                            <LazyLoadImage
                                effect="opacity"
                                src={src}
                                alt={alt}
                                width={'100%'}
                                height={maxHeight}
                            />
                        </picture>

                    </div>
                </div>
            </div>
        </Main>
    );
};

export { Image };
