/* eslint-disable no-unused-vars */
import { Template } from '@components';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ContentPixBoleto } from './messages/pix-boleto';
import { ContentCreditCard } from './messages/credit-card';

const CheckoutSuccess = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();
    const { pxToRem } = typography;

    const { state } = useLocation();

    return (
        <Box pt={matches ? 6 : 0} pb={6}>
            <Template.TemplateSuccess backgroundColor={palette.status.accepted}>
                {state.paymentType &&
                    (state.paymentType !== 'CARD' && !!state.billingLink ?
                        <ContentPixBoleto billing_link={state.billingLink} /> :
                        <ContentCreditCard />
                    )
                }
                <Typography
                    variant='h2'
                    component='p'
                    align='center'
                    fontSize={pxToRem(16)}
                    fontWeight={typography.fontWeightMedium}
                    color={palette.dark}
                    sx={{
                        '& a': {
                            color: palette.dark,
                            textDecoration: 'underline',
                        },
                    }}>
                    Acompanhe tudo sobre o seu pedido
                    em  <Link to='/meus-pedidos'>"Meus Pedidos"</Link>
                </Typography>
            </Template.TemplateSuccess>
        </Box>
    );
};

export { CheckoutSuccess };
