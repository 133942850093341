/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom';
import { Header } from '../../../header/dekstop/default';
import { MobileHeader } from '../../../header/mobile/default';
import { Box, Container, Stack, useTheme } from '@mui/material';
import { Main } from '../../../../design-system/templates';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BreakpointsSizesTypes } from '@types';
import { useUser } from '@hooks/user';
import { Footer } from '../../..';
import { skeleton } from './skeleton';
import { sx } from './sx';
import { useGlobal } from '@hooks/global';
import { useLoad } from '@hooks/load';

export function RouterLayoutSideMenu() {
    const { servicesLoad } = useLoad();
    const { breakpoints } = useTheme();
    const mediaQuery = (size: BreakpointsSizesTypes) => useMediaQuery(
        `(min-width:${breakpoints.
            values[size as BreakpointsSizesTypes]}px)`,
    );
    const { css } = sx();
    const skel = skeleton();
    const navigate = useNavigate();

    const user = useUser();
    const global = useGlobal();
    const [loggedUser, setLogged] = React.useState(false);

    const isLoadedServices = (
        user.info.isLoaded
    );

    React.useEffect(() => {
        servicesLoad({
            loadList: ['user', 'cart', 'global'],
            application: global.app.view.application,
        });
    }, []);

    React.useEffect(() => {
        servicesLoad({
            loadList: ['favorites'],
            application: global.app.view.application,
        });
    }, [global.app.view.application.type]);

    React.useEffect(() => {
        if (user.info.isLoaded) {
            setLogged(true);
        }
        if (user.info.status === 403) {
            setLogged(false);
            setTimeout(() => {
                navigate('/auth/login');
            }, 1000);
        }
    }, [user.info.isLoaded]);

    return (
        <div className="h-full">
            {mediaQuery('md') && !isLoadedServices && skel.headerDefault}
            {!mediaQuery('md') && !isLoadedServices && skel.headerMobile}

            {mediaQuery('md') && (
                isLoadedServices &&
                <Header />
            )}
            {!mediaQuery('md') && (
                isLoadedServices &&
                <MobileHeader
                    user={user.info}
                    cart={user.cart}
                    favorites={user.favorites}
                    global={global.app.view.application}
                />
            )}
            <Container
                sx={css.container.wrapper}
                maxWidth='lg'>
                <Stack direction='row'>
                    {mediaQuery('lg') && (
                        <Box sx={css.container.main.wrapper}>
                            {loggedUser && <Main origin={user.info.data?.origin} />}
                            {!loggedUser && skel.sideMenu}
                        </Box>
                    )}
                    <Box sx={css.container.outlet.wrapper}>
                        {loggedUser && <Outlet />}
                        {!loggedUser && skel.content}
                    </Box>
                </Stack>
            </Container>
            {mediaQuery('md') ?
                <Footer.Default /> :
                <Footer.Mobile />
            }
            <ScrollRestoration />
        </div>
    );
}

export default RouterLayoutSideMenu;
