/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Grid,
    InputAdornment,
    ListItemText,
    MenuItem,
    Radio,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { money, numbers, rem } from '@utils';
import { SubmitHandler, useForm } from 'react-hook-form';

import $ from 'jquery';
import 'jquery-mask-plugin';
import { TempFilterProps } from '@interfaces/Filters';
import { useFilters } from '@hooks/filters';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FILTERS_KEYS } from '@static/filters';
import { FiltersType } from '@types';
import { filterProductVirtualOptions } from '@static/menus';

type VirtualType = 'true' | 'false';

export const ProductType = () => {
    const { handleFilters, filters, externalActions } = useFilters();
    const [search, setSearch] = useSearchParams();
    const location = useLocation();
    const [virtual, setVirtual] = useState<string | undefined>();

    const handleVirtual = (virtual: 'true' | 'false') => {
        search.delete(FILTERS_KEYS.PRODUCT_TYPE);
        search.set(FILTERS_KEYS.PRODUCT_TYPE, `${virtual}|f45d455eb`);
        search.set(FILTERS_KEYS.ALEATORY,
            numbers.randomNumber({ maxRange: 100 }).toString());
        setSearch(search);

        handleFilters(FILTERS_KEYS.PRODUCT_TYPE as FiltersType, [{
            _id: 'f45d455eb',
            filterType: FILTERS_KEYS.PRODUCT_TYPE,
            name: virtual === 'false' ? 'Físico' : 'Virtual',
        }] as TempFilterProps[]);
    };

    useEffect(() => {
        setVirtual(search.get('virtual')?.split('|')[0]);
    }, [location.search]);

    return (
        <Box mt={1}>
            {filterProductVirtualOptions.map((item, i) => (
                <>
                    <MenuItem
                        key={item._id}
                        onClick={() => handleVirtual(item.value as VirtualType)}
                        sx={{ pt: 1 }}>
                        <ListItemText sx={{
                            '& > span': {
                                textWrap: 'balance',
                            },
                        }}>
                            {item.name}
                        </ListItemText>
                        <Radio
                            checked={virtual === item.value}
                            onChange={() => handleVirtual(item.value as VirtualType)}
                            value="virtual"
                            size='small'
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                        />
                    </MenuItem>
                    {i < filterProductVirtualOptions.length-1 &&
                        <Divider light />
                    }
                </>
            ))}
            {/* <MenuItem onClick={() => handleVirtual('physical')} sx={{ pt: 1 }}>
                <ListItemText sx={{
                    '& > span': {
                        textWrap: 'balance',
                    },
                }}>
                    Físicos
                </ListItemText>
                <Radio
                    checked={virtual === 'physical'}
                    onChange={() => handleVirtual('physical')}
                    value="physical"
                    size='small'
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                />
            </MenuItem> */}
        </Box>
    );
};
