import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

interface VirtualTagProps {
    size: 'small' | 'medium';
}

const handleSize = {
    small: '10px',
    medium: '12px',
};

export const VirtualTag = ({ size }: VirtualTagProps) => {
    const { palette } = useTheme();
    return (
        <Box sx={{
            backgroundColor: palette.primaryColor,
            px: 0.8,
            py: 0.1,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '6.25rem',
            color: palette.common.white,
        }}>
            <Typography sx={{
                fontSize: handleSize[size],
            }}>
                Virtual
            </Typography>
            <I
                style={{ marginLeft: '0.25rem' }}
                fontSize={handleSize[size]}
                icon={['far', 'laptop']} />
        </Box>
    );
};
