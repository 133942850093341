import { alpha, useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette } = useTheme();

    const css = {
        overlayMenuItem: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 99,
            backgroundColor: alpha(palette.common.white, 0.5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            userSelect: 'none',
        },
        skeleton: {
            wrapper: {
                mt: 1,
                mb: 1,
                pl: 1,
                pr: 1,
            },
            content: {
                wrapper: {
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    mr: 4,
                },
                text: {
                    fontSize: '12px',
                    ml: 1,
                },
            },
        },
        categoriesHistory: {
            wrapper: {
                display: 'flex',
                backgroundColor: '#f1f1f1',
                overflow: 'hidden',
                overflowX: 'auto',
                // pt: 1,
            },
        },
        icons: {
            left: {
                ...{
                    minWidth: rem(26) + ' !important',
                    cursor: 'pointer',
                },
                '&>svg': {
                    fontSize: rem(14) + ' !important',
                    color: palette.dark,
                },
            },
        },
        categoryMenu: {
            title: {
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                cursor: 'pointer',
            },
        },
    };

    return { css };
};
