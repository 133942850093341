import { RemoveFiltersListProps, TempFilterProps } from '@interfaces/Filters';
import { MountURLParamsListForListProps, ShowCasesProps } from '@interfaces/Services';
import { FiltersType } from '@types';

const RESPONSE = {
    isLoaded: false,
    status: 0,
    message: '',
};

export const DEFAULTS = {
    QUICKLINKS: {
        LIST: {
            ...RESPONSE,
            data: [] as MountURLParamsListForListProps[],
        },
    },
    SHOWCASES: {
        LIST: {
            ...RESPONSE,
            data: [] as ShowCasesProps[],
        },
    },
    FILTERS_LIST: {
        categoryList: [] as TempFilterProps[],
        valueRange: [] as TempFilterProps[],
        size: [] as TempFilterProps[],
        orderBy: [] as TempFilterProps[],
        pageNumber: [] as TempFilterProps[],
        aleatory: [] as TempFilterProps[],
        limit: [] as TempFilterProps[],
        storeUrl: [] as TempFilterProps[],
        virtual: [] as TempFilterProps[],
    },
    TEMP_FILTERS_LIST: {
        categoryList: [] as TempFilterProps[],
        valueRange: [] as TempFilterProps[],
        size: [] as TempFilterProps[],
        orderBy: [] as TempFilterProps[],
        pageNumber: [] as TempFilterProps[],
        aleatory: [] as TempFilterProps[],
        limit: [] as TempFilterProps[],
        storeUrl: [] as TempFilterProps[],
        virtual: [] as TempFilterProps[],
    },
    LIST_ACTIVE_FILTERS: {
        isLoaded: false,
        data: [] as RemoveFiltersListProps[],
    },
    FILTERS_KEYS: [
        'size',
        'categoryList',
        'valueRange',
        'orderBy',
        'pageNumber',
    ] as FiltersType[],
};
