/* eslint-disable max-len */
// import PlaceholderIMG from '@img/no-image.png';
import { FileListProps, ResponseHandleFiles } from '@interfaces/Utils';
import { MESSAGES } from './MESSAGES';
import { CONSTANTS } from '@static/constants';
import config from '../../configs/config.json';

const checkPath = (path: string) => {
    if (path && (path.includes('.com') || path.includes('.net'))) {
        return path;
    }

    return config.apis.url + '/files/' + path;
};

// const load2 = (path: string, source: 'local' | 'server') => {
//     const url = 'https://genericsupplierprd.blob.core.windows.net/images/a7f2cc47-547a-47db-0bd4-08dab2d17775%2F470c33f3-da52-44b9-a91c-26ebf2395a79ShoulderBagMexicoAzul.jpg';

//     if (path) {
//         if (config.apis.url.includes('rewardhub.com.br')) {
//         } else {
//         }
//     }
// };

const load = (path: string, source: 'local' | 'server') => {
    // if (path) {
    //     if (source === 'server') {
    //         return config.apis.url + '/files/' + path;
    //     }

    //     if (source === 'local') {
    //         return path;
    //     }
    // }

    return source === 'local' ? path : checkPath(path);
};

const fileOBJ = (idx: number, file: FileList, type: 'image' | 'video') => {
    const dataImageURL = URL.createObjectURL(file[idx]);
    const imageToArray = dataImageURL.split('/');
    const imageHash = imageToArray[imageToArray.length - 1];
    return ({
        id: imageHash,
        src: dataImageURL,
        content: dataImageURL,
        file: file[idx],
        type,
    });
};

const loadVideo = (file: Blob | MediaSource) => new Promise((resolve, reject) => {
    let response = {};
    try {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function() {
            resolve(this);
        };

        video.onerror = () => {
            reject(new Error(MESSAGES.MIDIA_FORMAT_ERROR));
        };

        video.src = window.URL.createObjectURL(file);
        return video;
    } catch (e: any) {
        return response = {
            data: null,
            message: e.message,
        };
    } finally {
        return response;
    }
});

const handleFiles = async (event: React.ChangeEvent<HTMLInputElement>,
    fileList?: FileListProps[]) => {
    let response = {} as ResponseHandleFiles;
    const files: FileListProps[] = [];
    const tempVideoFileList: FileListProps[] = [];
    const tempList = [];
    const imageFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const videoFileTypes = ['video/mp4', 'video/quicktime'];
    try {
        const selectedFiles = event.target.files as FileList;
        // Identificando se o arquivo e vídeo
        for (let i = 0; selectedFiles.length > i; i++) {
            if (selectedFiles[i].type.includes('image/')) {
                if (!imageFileTypes.includes(selectedFiles[i].type)) {
                    throw new Error(MESSAGES.MIDIA_FORMAT_ERROR);
                }
            }

            if (selectedFiles[i].type.includes('video/')) {
                if (!videoFileTypes.includes(selectedFiles[i].type)) {
                    throw new Error(MESSAGES.MIDIA_FORMAT_ERROR);
                }
            }

            if (selectedFiles[i].type.includes('video/')) {
                tempList.push(selectedFiles[i].type);
                if ((tempList.length !== 1)) {
                    throw new Error(MESSAGES.UPLOAD_VIDEO_LIMIT_ERROR);
                }

                if (fileList) {
                    const videoOnList = fileList.find((img) => img.type === 'video');

                    if (videoOnList) {
                        throw new Error(MESSAGES.UPLOAD_VIDEO_LIMIT_ERROR);
                    }
                    tempVideoFileList.push(fileOBJ(0, selectedFiles, 'video'));
                }
            }
        }

        const videoFile = tempVideoFileList.find((item) => item.type === 'video');

        if (videoFile) {
            const video = await loadVideo(videoFile.file) as any;
            if (video.duration >= CONSTANTS.UPLOAD.VIDEO.DURATION_LIMIT) {
                throw new Error(MESSAGES.VIDEO_DURATION_LIMIT_ERROR);
            } else {
                files.push(...tempVideoFileList);
            }
        }

        if (selectedFiles.length > 1) {
            for (let i = 0; selectedFiles.length > i; i++) {
                if (!(selectedFiles[i].type.includes('video/'))) {
                    if (i === 4) break;
                    files.push(fileOBJ(i, selectedFiles, 'image'));
                }
            }
        } else {
            if (!(selectedFiles[0].type.includes('video/'))) {
                files.push(fileOBJ(0, selectedFiles, 'image'));
            }
        }

        const count = files.length;
        if (!!(count)) {
            return response = {
                message: count > 1 ?
                    count + ' arquivos adicionados' :
                    '1 arquivo adicinado',
                error: false,
                files: files,
            };
        } else {
            throw new Error(MESSAGES.UPLOAD_ERROR);
        }
    } catch (error: any) {
        return response = {
            message: error.message,
            error: true,
            files: [],
        };
    } finally {
        return response;
    }
};

export const files = {
    handle: handleFiles,
    load,
};
