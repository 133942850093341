import { Box, Typography } from '@mui/material';
import { files, rem } from '@utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';


interface QuicklinkItemProps {
    onClick: () => void;
    mr: string | 0;
    size: 84 | 64;
    title: string;
    srcBanner: string;
}


const QuicklinkItem: React.FC<QuicklinkItemProps> = ({
    onClick, mr, size, title, srcBanner,
}) => {
    return (
        <Box
            onClick={onClick}
            display='flex'
            flexDirection='column'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            mr={mr}
        >
            <LazyLoadImage
                alt={title}
                src={files.load(srcBanner, 'server')}
                height={size}
                width={size}
                style={{ borderRadius: '100%', marginBottom: rem(4), objectFit: 'cover' }}
                // sx={{ bgcolor: 'purple', width: size, height: size, mb: rem(4) }}
            />
            <Typography
                textAlign='center'
                fontSize={rem(13)}
                fontWeight={600}
                color='#2F3640'
                variant='subtitle2'>
                {title}
            </Typography>
        </Box>
    );
};

export { QuicklinkItem };

